import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Toaster from './components/ui/Sonner'
import './assets/css/index.css'
import 'chart.js/auto'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<Toaster closeButton richColors />
		<BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
			<App />
		</BrowserRouter>
	</React.StrictMode>
)

import DeskNav from './DeskNav'
import PhoneNav from './PhoneNav'

const navLinks = [
	{
		name: 'Home',
		to: '/'
	},
	{
		name: 'Business',
		children: [
			{ name: 'Health days', to: '/health-days' },
			{ name: 'Lunch & Learn', to: '/lunch-learn' }
		]
	},
	{
		name: 'Clinic',
		to: '/clinic'
	},
	{
		name: 'Contact',
		to: '/contact'
	},
	{
		name: 'About',
		to: '/about'
	}
]

const NavBar = () => {
	return (
		<>
			<DeskNav
				navLinks={navLinks}
				className="hidden lg:block"
			/>

			<PhoneNav
				navLinks={navLinks}
				className="block lg:hidden"
			/>
		</>
	)
}

export default NavBar

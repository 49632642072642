import { Outlet } from 'react-router-dom'
import Navbar from "./navBar/NavBar"
import Footer from './Footer'

const Layout = () => {
	return (
		<div className="h-full">
			<Navbar />
			<Outlet />
			<Footer />
		</div>
	)
}

export default Layout
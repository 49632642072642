import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import DeskNavLink from './DeskNavLink'

import logo from '../../../assets/img/web/twdLogoGreen.png'

interface DeskNavProps {
    navLinks: any,
    className: string,
}

const DeskNav = ({ navLinks, className }: DeskNavProps) => {
    const [activeSubLink, setActiveSubLink] = useState<any>()

    useEffect(() => {
        const toggleSubLink = (e: MouseEvent) => {
            const deskNavElement = document.getElementById('deskNav')

            if (deskNavElement && !deskNavElement.contains(e.target as HTMLElement)) {
                setActiveSubLink(undefined)
            }
        }

        document.addEventListener("click", toggleSubLink)

        return () => {
            document.removeEventListener("click", toggleSubLink)
        }
    }, [activeSubLink])

    return (
        <nav className={`${className} mx-auto max-w-[110rem] px-4 pb-4 pt-6`}>
            <div className="flex items-center justify-between">
                <Link to="/" onClick={() => setActiveSubLink(undefined)}>
                    <img className="w-[280px]" src={logo} alt="The Wellbeing Doctors Logo" />
                </Link>
                <div id='deskNav' className="flex z-50 items-center gap-5">
                    {navLinks.map((navLink: any) => (
                        <DeskNavLink key={navLink.name} navLink={navLink} setActiveSubLink={setActiveSubLink} activeSubLink={activeSubLink} />
                    ))}

                    {activeSubLink?.children?.length > 0 && (
                        <div className="absolute z-10 mt-44 flex flex-col gap-4 w-screen left-0 px-10 py-6 shadow-lg border bg-white">
                            {activeSubLink.children?.map((childLink: any) => (
                                <Link
                                    key={childLink.to}
                                    to={childLink.to}
                                    className="text-slate-700 hover:text-gray-600"
                                    onClick={() => setActiveSubLink(undefined)}
                                >
                                    {childLink.name}
                                </Link >
                            ))}
                        </div>
                    )}

                    <a
                        href="https://calendly.com/edward-l4kg/30min?month=2024-04"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[#2BFF89] bg-[#173F47] font-medium rounded-full text-sm px-6 py-3 text-center h-fit"
                        onClick={() => setActiveSubLink(undefined)}
                    >
                        Get started
                    </a>
                </div>
            </div>
        </nav>
    )
}

export default DeskNav

import { Link } from 'react-router-dom'
import { IoMdArrowRoundForward } from 'react-icons/io'

interface PhoneNavLinkProps {
    navLink: any,
    setActiveSubLink: (value: any) => void
    setShowNav: (value: any) => void
}

const PhoneNavLink = ({ navLink, setActiveSubLink, setShowNav }: PhoneNavLinkProps) => {
    return (
        <Link
            to={navLink?.children?.length > 0 ? "#" : navLink.to}
            onClick={navLink?.children?.length > 0 ? () => setActiveSubLink(navLink) : () => setShowNav(false)}
            className="flex items-center gap-x-40 px-4 py-2 text-xl text-slate-700 hover:text-gray-600"
        >
            {navLink.name}
            {navLink?.children?.length > 0 && <IoMdArrowRoundForward className='text-xl ml-auto md:ml-4' />}
        </Link>
    )
}

export default PhoneNavLink




import { useState } from 'react'
import { Link } from 'react-router-dom'
import { IoMdArrowRoundBack, IoMdClose } from 'react-icons/io'
import { RxHamburgerMenu } from 'react-icons/rx'

import PhoneNavLink from './PhoneNavLink'

import logo from '../../../assets/img/web/twdLogoGreen.png'

interface PhoneNavProps {
    navLinks: any,
    className: string
}

const PhoneNav = ({ navLinks, className }: PhoneNavProps) => {
    const [showNav, setShowNav] = useState<boolean>(false)
    const [activeSubLink, setActiveSubLink] = useState<any>()

    return (
        <nav className={`${className} flex items-center justify-between px-4 pb-4 pt-6`}>
            <Link to="/">
                <img className="w-[235px]" src={logo} alt="The Wellbeing Doctors Logo" />
            </Link>

            <button onClick={() => setShowNav(true)}>
                <RxHamburgerMenu size={30} className="text-black opacity-60" />
            </button>

            <div className={`${showNav ? 'block' : 'hidden'} absolute z-50 top-0 left-0 w-full h-full bg-white`}>
                <div className='flex py-6 px-4'>
                    <button onClick={() => setShowNav(false)} className="ml-auto">
                        <IoMdClose size={30} className="text-gray-800 opacity-70" />
                    </button>
                </div>

                {!activeSubLink && navLinks.map((navLink: any) => (
                    <PhoneNavLink key={navLink.name} navLink={navLink} setActiveSubLink={setActiveSubLink} setShowNav={setShowNav} />
                ))}

                {activeSubLink?.children?.length > 0 && (
                    <div className='flex flex-col gap-3'>
                        <button
                            onClick={() => setActiveSubLink(undefined)}
                            className="flex items-center px-3 py-2 text-xl w-full bg-slate-100 rounded-md text-slate-700"
                        >
                            <IoMdArrowRoundBack className='mr-2' />
                            {activeSubLink.name}
                        </button>
                        {activeSubLink.children.map((childLink: any) => (
                            <Link
                                key={childLink.to}
                                to={childLink.to}
                                className="text-xl text-slate-700 px-4 hover:text-gray-600"
                                onClick={() => setShowNav(false)}
                            >
                                {childLink.name}
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </nav>
    )
}

export default PhoneNav



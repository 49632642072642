import { Link } from 'react-router-dom'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

interface DeskNavLinkProps {
    navLink: any,
    activeSubLink: any
    setActiveSubLink: (value: any) => void
}

const DeskNavLink = ({ navLink, activeSubLink, setActiveSubLink }: DeskNavLinkProps) => {
    return (
        <Link
            to={navLink?.children?.length > 0 ? "#" : navLink.to}
            onClick={navLink?.children?.length > 0 ? () => setActiveSubLink(activeSubLink === navLink ? undefined : navLink) : () => setActiveSubLink(undefined)}
            className="flex gap-x-2 items-center text-slate-700 hover:text-gray-600"
        >
            {navLink.name}
            {navLink?.children?.length > 0 && (activeSubLink ? <IoIosArrowUp /> : <IoIosArrowDown />)}
        </Link>
    )
}

export default DeskNavLink